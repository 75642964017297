import {Signal} from "@preact/signals";
import * as L from "leaflet";
import "leaflet.locatecontrol/src/L.Control.Locate";
import {Component, h} from 'preact';

import i18n from "../../i18n";
import {Checkpoint} from "../../models/checkpoint";
import {LngLat} from "../../models/track-point";
import {validLngLat} from "../../utils/lnglat";

import "leaflet.locatecontrol/src/L.Control.Locate.scss";
import 'leaflet/dist/leaflet.css';

const RADIUS = 1200;

type LeafletMapProps = {
    checkpoints?: Checkpoint[];
    geoJson?: any;
    startPoint?: LngLat;
    geoLocation?: Signal<LngLat>;
}

const DEFAULT_CENTER = new LngLat(30.317, 59.95);
const DEFAULT_ZOOM = 14;

class LeafletMap extends Component<LeafletMapProps> {
    map: L.Map;
    geoLocation?: Signal<LngLat>;
    locateControl: L.Control;

    constructor(props: LeafletMapProps) {
        super(props);
        this.geoLocation = props.geoLocation;
    }

    render = () => <div id="map" style={{height: '20rem', maxWidth: '800px'}} />

    componentDidMount() {
        this.map = L.map('map')
            .setView(this.props.startPoint || this.props.checkpoints?.[0]?.coordinates || DEFAULT_CENTER, DEFAULT_ZOOM);

        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
        }).addTo(this.map);

        if (this.props.geoJson) {
            L.geoJSON(this.props.geoJson, {
                style: {
                    color: 'red',
                    weight: 2,
                    opacity: 0.5,
                    lineJoin: 'round',
                    lineCap: 'round',
                },
            }).addTo(this.map);
        }

        this.props.checkpoints?.filter(cp => validLngLat(cp.coordinates)).reverse()
            .forEach(cp => {
                    L.popup({
                        keepInView: false,
                        closeButton: false,
                        closeOnClick: false,
                        closeOnEscapeKey: false,
                    })
                        .setLatLng(cp.coordinates)
                        .setContent(cp.name || i18n.t("map.control"))
                        .addTo(this.map);
                    if (this.geoLocation) {
                        L.circle(cp.coordinates, RADIUS, {weight: 1, color: 'green'}).addTo(this.map);
                    }
                }
            );

        if (this.geoLocation) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.locateControl = L.control.locate({
                flyTo: true,
                showCompass: true,
                cacheLocation: true
            }).addTo(this.map);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.locateControl.start();
            this.map.on('locationfound', this.onLocationFound.bind(this));
            this.map.on('locationerror', event => console.error('Geo location error', event));
        }
    }

    componentWillUnmount() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.locateControl?.stop();
    }

    onLocationFound(event: GeolocationCoordinates) {
        this.geoLocation.value = new LngLat(event.longitude, event.latitude);
    }
}

export default LeafletMap;
